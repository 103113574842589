.container {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.container .notification {
  position: relative;
  width: 260px;
  padding: 1rem;
  text-align: left;
  background-color: #8fd7a5;
  font-family: "Corbel", Arial;
  font-weight: 700;
  color: var(--black);
  word-wrap: break-word;
  opacity: 0;
}
.container .notification.error {
  background-color: #da4338;
  color: var(--white);
}
.container .notification.fadeIn {
  animation: fadeIn ease 0.5s forwards;
}
.container .notification.fadeOut {
  animation: fadeOut ease-out 0.5s backwards;
}

.container .notification button {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 1.25px 5px;
  border: 1px solid var(--black);
  border-radius: 4px;
  background-color: var(--white);
  color: var(--black);
  transition: 0.2s ease background-color;
}
.container .notification button::before {
  content: "\2715";
  color: var(--black);
}
.container .notification button:hover {
  background-color: #e1e1e1;
}
.container .notification button:active {
  top: -3.75px;
}

@keyframes fadeIn {
  0% {
    right: -150px;
    opacity: 0;
  }
  70% {
    right: 20px;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    bottom: 0;
    opacity: 1;
  }
  20% {
    bottom: 20px;
  }
  100% {
    bottom: -100px;
    opacity: 0;
  }
}
