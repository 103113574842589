.backBtn {
  width: 150px;
  height: 120px;
  background: url("../../../static/btn_back.png") no-repeat center
    center/contain;
}

.businessContainer {
  position: relative;
  width: 200px;
  height: 200px;
  cursor: pointer;
  transition: 0.2s ease opacity;
}
.businessContainer:hover,
.businessContainer:active {
  opacity: 0.65;
}
.businessContainer:active {
  top: 2px;
}
.businessContainer[aria-disabled="true"] {
  opacity: 0.65;
  filter: grayscale(35%);
  cursor: not-allowed;
}
@media screen and (max-width: 470px) {
  .businessContainer {
    transform: scale(0.9);
  }
}

.businessContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.businesses {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.5rem 1rem;
  padding-block: 2rem;
}

.details {
  width: 85%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.businessTitle {
  text-align: left;
  color: var(--pbrown);
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.businessTitle h2 {
  font-size: 3rem;
  font-family: "BebasNeue", Arial;
}
.businessTitle p {
  font-size: 18px;
  font-family: "Corbal", Arial;
  margin-bottom: 40px;
}
.detailsHeader img {
  height: 150px;
  width: 150px;
  margin-bottom: 2rem;
}

.detailsContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.detailsContentView {
  display: flex;
  justify-content: center;
  width: 70%;
  background-color: var(--pbrown-fade);
}
.detailsOptions {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}
.detailsOptions div {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}
.detailsOptions div label {
  width: 150px;
  margin-bottom: 1rem;
}
.detailsOptions div input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.detailsOptions div label:has(> input[type="radio"]:checked) {
  color: white;
}

@media screen and (max-width: 800px) {
  .details {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .businessTitle {
    margin-left: 10px;
    margin-top: 0;
  }
  .details {
    width: 100%;
  }
  .detailsHeader {
    flex-direction: column;
    align-items: center;
  }
  .detailsHeader .businessTitle {
    width: 90%;
  }
  .detailsContent {
    flex-direction: column-reverse;
  }
  .detailsContentView {
    width: 100%;
  }
  .detailsOptions {
    position: relative;
    width: 100%;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 30px;
  }
  .detailsOptions a {
    align-self: center;
  }
}
.videoLabel {
  width: 110px;
  height: 50px;
  background: url("../../../static/businessSlidesBtns/btn-video-normal-notsel.png")
    no-repeat center center/contain;
  cursor: pointer;
}
.videoLabel:hover {
  background: url("../../../static/businessSlidesBtns/btn-video-hover.png")
    no-repeat center center/contain;
}
.videoLabel:active {
  background: url("../../../static/businessSlidesBtns/btn-video-pressed.png")
    no-repeat center center/contain;
}
input[type="radio"]:checked + .videoLabel {
  background: url("../../../static/businessSlidesBtns/btn-video-normal-sel.png")
    no-repeat center center/contain;
}
.questionLabel {
  width: 110px;
  height: 50px;
  background: url("../../../static/businessSlidesBtns/btn-question-normal-notsel.png")
    no-repeat center center/contain;
  cursor: pointer;
}
.questionLabel:hover {
  background: url("../../../static/businessSlidesBtns/btn-question-hover.png")
    no-repeat center center/contain;
}
.questionLabel:active {
  background: url("../../../static/businessSlidesBtns/btn-question-pressed.png")
    no-repeat center center/contain;
}
input[type="radio"]:checked + .questionLabel {
  background: url("../../../static/businessSlidesBtns/btn-question-normal-sel.png")
    no-repeat center center/contain;
}
.meetupLabel {
  width: 110px;
  height: 50px;
  background: url("../../../static/businessSlidesBtns/btn-meetup-normal-notsel.png")
    no-repeat center center/contain;
  cursor: pointer;
}
.meetupLabel:hover {
  background: url("../../../static/businessSlidesBtns/btn-meetup-hover.png")
    no-repeat center center/contain;
}
.meetupLabel:active {
  background: url("../../../static/businessSlidesBtns/btn-meetup-pressed.png")
    no-repeat center center/contain;
}
input[type="radio"]:checked + .meetupLabel {
  background: url("../../../static/businessSlidesBtns/btn-meetup-normal-sel.png")
    no-repeat center center/contain;
}
