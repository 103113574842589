.challengeContainer {
  max-width: 800px;
  display: flex;
  flex-direction: column-reverse;
  max-height: 80dvh;
  overflow-y: auto;
}
.challengeContainer p {
  /* current font does not have lowercase */
  font-size: 20px;
  color: var(--pbrown);
  padding: 20px 0;
  white-space: pre-line;
  text-align: left;
}
.challengeLabel {
  cursor: pointer;
  text-decoration: underline;
}
.challengeLabel:hover {
  opacity: 60%;
}