.cooldownContainer {
  align-self: flex-end;
  font-size: 26px;
}
.cooldownContainer p {
  color: var(--pbrown);
}
.cooldownContainer span {
  color:  var(--orange);
  font-weight: bolder;
}