.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
  margin-block: 1.5rem;
}
@media screen and (max-width: 914px) {
  .container {
    padding-inline: 0.5rem;
  }
}

.container > div {
  display: flex;
}
@media screen and (max-width: 711px) {
  .container {
    justify-content: center;
  }

  :global(.privacy) {
    left: 50% !important;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 362px) {
  .container {
    transform: scale(0.9);
  }
}

.container div button,
.container div a {
  width: 60px;
  height: 60px;
  border: 0;
  cursor: pointer;
}
.container div button {
  margin-right: 1rem;
}
@media screen and (max-width: 390px) {
  .container div p {
    margin-right: 1rem;
  }

  .container div button {
    margin-right: 5px;
  }
}

.container div .chat {
  background: url("../../../static/btn_chat_normal.png") no-repeat center
    center/contain;
}
.container div .chat:hover {
  background: url("../../../static/btn_chat_hover.png") no-repeat center
    center/contain;
}
.container div .chat:active {
  background: url("../../../static/btn_chat_pressed.png") no-repeat center
    center/contain;
}

.container div .questions {
  background: url("../../../static/btn_question_normal.png") no-repeat center
    center/contain;
}

.container div .checkout {
  background: url("../../../static/btn_checkout_normal.png") no-repeat center
    center/contain;
}
.container div .checkout:hover {
  background: url("../../../static/btn_checkout_hover.png") no-repeat center
    center/contain;
}
.container div .checkout:active {
  background: url("../../../static/btn_checkout_pressed.png") no-repeat center
    center/contain;
}

.container .timer {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: var(--pbrown);
}

.container .timer time {
  font-size: 60px;
  margin-inline: 0.5rem;
}
