.resultsContainer {
  min-height: 680px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.bannerImage {
  width: 100%;
}
.eventSelection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.otherContainer {
  padding: 0;
  margin: 0;
  max-height: 500px;
  min-height: 400px;
  width: 90%;
  overflow: auto;
  scroll-behavior: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.otherContainer li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% / 2 - 40px);
  max-height: 120px;
  margin: 20px;
}
.otherContainer li:last-child {
  margin-right: auto;
}
.otherContainer .winnerContainer {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: auto;
  max-height: 120px;
  overflow: auto;
}
.otherContainer img {
  width: 100px;
  height: 100px;
}
.otherImageContainer {
  position: relative;
}
.otherImageContainer .partnerLogo {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: -10px;
  object-fit: contain;
  transition: all 0.2s linear;
}
.otherImageContainer .partnerLogo:hover {
  transform: scale(2);
}
.resultDisplayTop {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--pbrown);
}
.resultDisplayTop img {
  width: 250px;
  height: 250px;
}
.resultDisplayTop ul {
  list-style: none;
  text-align: left;
}
.resultDisplayBottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  color: var(--pbrown);
  font-size: 20px;
}
.resultDisplayBottom .partnerRaffleItems {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: calc(100% / 2);
  text-align: left;

}
.resultDisplayBottom .partnerRaffleItems img {
  width: 100px;
  height: 100px;
  margin-right: 20px;
}
.logoPagination {
  margin: 3px;
  border: 3px solid transparent;
  width: 60px;
  height: 60px;
}
.logoSelected {
  border: 3px solid red;
}
.logoPagination img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.prevButton {
  background: url("../../../static/eventResults/btn_back.png") no-repeat center center/contain;
  height: 80px;
  width: 120px;
}
.nextButton {
  background: url("../../../static/eventResults/btn_next.png") no-repeat center center/contain;
  height: 80px;
  width: 120px;
}
