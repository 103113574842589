/* <======================================/ Reset \======================================> */
.form[id="resetPasswordForm"] {
  padding: 26px 0 0 34px;
}

.form[id="resetPasswordForm"] > div {
  width: 100%;
  max-width: 335px;
}
.form[id="resetPasswordForm"] > div:first-child {
  margin-bottom: 2rem;
}
.form[id="resetPasswordForm"] > div:last-child {
  text-align: right;
  max-width: 437px;
}

@media screen and (max-width: 494px) {
  .form[id="resetPasswordForm"] > div:last-child button {
    margin-top: 0.75rem;
  }
}

@media screen and (max-width: 403px) {
  .form[id="resetPasswordForm"] {
    padding: 26px 0 0 0;
  }
}

/* <======================================/ Shared \======================================> */
.form > div {
  position: relative;
  text-align: left;
}

.form div input {
  width: 100%;
}

.form div small:global(.formError) {
  width: fit-content;
  margin-top: 6px;
}

/* <======================================/ Profile \======================================> */
.form[id="updatePasswordForm"] {
  --_labelWidth: 105.5px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media screen and (min-width: 664px) {
  .form[id="updatePasswordForm"] > div {
    display: flex;
  }
  .form[id="updatePasswordForm"] > div:last-child {
    margin-left: calc(var(--_labelWidth) + 0.5rem);
  }

  .form[id="updatePasswordForm"] div label {
    position: relative;
    top: 4px;
    white-space: nowrap;
    margin-right: 0.5rem;
    min-width: var(--_labelWidth);
  }
}
