.left button {
  display: block;
  width: 74px;
  height: 74px;
  margin-bottom: 2rem;
  background: url("../../../static/btn_logout_normal.png") no-repeat
    center/contain;
  cursor: pointer;
}
.left button:hover {
  background: url("../../../static/btn_logout_hover.png") no-repeat center
    center/contain;
}
.left button:active {
  opacity: 0.7;
}

.left .errorLogo {
  max-width: 285px;
  max-height: 253px;
}

.right h1 {
  font-family: "BebasNeue", Arial;
  font-size: 82px;
  line-height: 0.8;
  color: var(--pbrown);
  margin-bottom: 2.5rem;
}

.right .timer {
  font-size: var(--heading-42);
  color: var(--pbrown);
  line-height: 64px;
  max-width: 415px;
}
.right .timer time,
.right .timer span {
  display: block;
  font-size: 100px;
  line-height: auto;
}
.right .timer a {
  font-size: var(--heading-28);
}

@media screen and (max-width: 682px) {
  .left button {
    margin-inline: auto;
    margin-bottom: 2rem;
  }

  .right h1 {
    font-size: 92px;
  }

  .right .timer {
    font-size: 52px;
    line-height: 74px;
  }
  .right .timer time,
  .right .timer span {
    font-size: 110px;
  }
}
