.questions {
  background: url("../../../static/btn_question_normal.png") no-repeat center
    center/contain;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  color: var(--pbrown);
  text-align: center;
}
.modalContainer div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modalContainer h2 {
  font-size: var(--heading-38);
  margin-bottom: 32px;
}
.modalContainer p {
  font-size: 21px;
  max-width: 80%;
}
.modalContainer p a:first-child {
  display: block;
  width: fit-content;
  margin-inline: auto;
  font-family: "Corbel", Arial;
  font-size: 21px;
  font-weight: 700;
  text-decoration: underline;
  opacity: 0.85;
  cursor: pointer;
}
.modalContainer p:first-child a {
  margin-block: 6px 24px;
}
.modalContainer p:last-child a {
  margin-block: 6px 0;
}
.modalContainer p a:hover {
  opacity: 0.5;
}
