.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  text-align: left;
  position: relative;
  width: fit-content;
  width: 50%;
}

.loaderContainer .text {
  font-size: var(--heading-28);
  color: var(--black);
  margin-bottom: 4px;
}

.loaderContainer .loader {
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 3.5px;
  background-color: var(--sBeige-fade);
}
.loaderContainer .loader::before {
  content: "";
  position: absolute;
  background-color: var(--orange);
  width: 0%;
  height: 10px;
  border-radius: 3.5px;
  animation: loading 3.5s ease-in-out infinite;
  box-shadow: var(--orange) 0px 2px 31px 0px;
}

@keyframes loading {
  50% {
    width: 100%;
  }

  100% {
    right: 0;
    left: unset;
  }
}
