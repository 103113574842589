.container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.container p {
  font-size: 24px;
  color: var(--pbrown);
}
.container div {
  display: flex;
  justify-content: space-around;
}
.container button {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: var(--pbrown);
  border: 2px solid var(--pbrown);
  padding: 10px 20px;
}
.container button:hover {
  opacity: 50%;
}