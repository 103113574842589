.lensButton {
  position: absolute;
  bottom: -15px;
  right: -15px;
  height: 25px;
  width: 25px;
  background: url("../../../static/magnifier.png") no-repeat center
    center/contain;
}
.backBtn:hover,
.backBtn:active {
  opacity: 0.65;
}
.backBtn:active {
  top: 3px;
}
.backBtn {
  width: 150px;
  height: 120px;
  background: url("../../../static/btn_back.png") no-repeat center
    center/contain;
}
.modalContainer {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalContainer h2 {
  font-size: 42px;
  color: var(--pbrown);
  margin-bottom: 50px;
}

.modalContainer .modalLeft {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 5rem;
}
.modalContainer .modalLeft img {
  height: 100%;
  width: 100%;
  min-width: 300px;
  height: 300px;
  margin: auto;
  object-fit: contain;
  margin: 50px 0;
}
.modalContainer .modalRight {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modalContainer .modalRight > p {
  font-family: "Corbal", Arial;
  font-size: 18px;
  color: var(--pbrown);
  margin-bottom: 50px;
}
.modalContainer .productExtras {
  display: flex;
  align-items: center;
  color: var(--pbrown);
  font-weight: bold;
}
.modalContainer .productExtras > :first-child {
  margin-right: 2rem;
  color: var(--pbrown);
  font-size: 26px;
}
.modalContainer .productExtras > :first-child span {
  display: block;
  font-size: 56px;
  font-weight: bold;
}
.modalContainer .productExtras img {
  object-fit: contain;
}
.modalContainer .productExtras a {
  display: block;
}
.modalContainer .sponsorLogo {
  width: 150px;
  height: 150px;
  padding: 10px;
}
@media screen and (max-width: 900px) {
 .modalContainer {
  flex-direction: column;
 } 
 .modalContainer .modalLeft {
  margin: 0;
  justify-content: space-between;
  width: 100%;
 }
 .modalContainer .productExtras {
  width: 100%;
  justify-content: space-evenly;
 }
 .modalContainer .productExtras div {
  justify-content: center;
  align-items: center;
 }
}
@media screen and (max-width: 500px) {
.modalContainer .modalLeft img {
  min-width: 200px;
}
}
