.card {
  position: relative;
  width: 200px;
  height: 155px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s ease opacity;
}
.card:hover,
.card:active {
  opacity: 0.65;
}
.card:active {
  top: 2px;
}
.card[aria-disabled="true"] {
  opacity: 0.65;
  filter: grayscale(35%);
  cursor: not-allowed;
}
@media screen and (max-width: 470px) {
  .card {
    transform: scale(0.9);
  }
}

.card img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: burlywood;
  animation: pulse 2.5s infinite;
}
.card img.loaded {
  background-color: transparent;
  animation: none;
}

.card h4 {
  position: relative;
  top: calc(99px);
  font-size: 24px;
  line-height: 0.8;
  font-weight: 400;
  color: var(--pbrown);
}

.card:nth-child(1) h4,
.card:nth-child(2) h4 {
  top: calc(95px - 8px);
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
