.optionsView {
  min-height: 350px;
  width: 100%;
}

.optionsViewVideo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionsView form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.optionsView form .challengeLabel {
  margin-bottom: 20px;
}

.optionsView form p {
  font-family: "Corbal", Arial;
  font-size: 17px;
  color: var(--pbrown);
}
.optionsView form p span {
  font-weight: 700;
}

.optionsView form .formSubmission {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.optionsView form .formSubmission > div {
  display: flex;
  justify-content: space-between;
}

.optionsView form .formSubmission div .inputContainer {
  width: 60%;
  margin-bottom: 20px;
}
.optionsView form .formSubmission div .inputContainer input {
  width: 100%;
}

.optionsView form .formSubmission div small {
  width: fit-content;
  margin-top: 6px;
}

.formButtons {
  display: flex;
  justify-content: flex-end;
}

.meetUp {
  height: 100%;
  color: var(--pbrown);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.meetUp h2 {
  font-family: "Corbel", Arial;
  font-size: 2rem;
}
.meetUp p {
  font-family: "Corbel", Arial;
  font-size: 2rem;
}
.meetOptions input:disabled + label {
  cursor: not-allowed;
}
.meetOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.meetOptions input {
  margin-right: 10px;
}
.meetOptions label {
  cursor: pointer;
}

.meetSubmit {
  align-self: flex-end;
}

.questionSubmit,
.meetSubmit {
  border: none;
  width: 150px;
  height: 50px;
  background: url("../../../../static/businessSlidesBtns/btn-submit-normal.png")
    no-repeat right center/contain;
  cursor: pointer;
}
.questionSubmit:hover,
.meetSubmit:hover {
  background: url("../../../../static/businessSlidesBtns/btn-submit-hover.png")
    no-repeat right center/contain;
}
.questionSubmit:active,
.meetSubmit:active {
  background: url("../../../../static/businessSlidesBtns/btn-submit-pressed.png")
    no-repeat right center/contain;
}
.meetSubmit:disabled,
.questionSubmit:disabled {
  background: url("../../../../static/businessSlidesBtns/btn-submit-hover.png")
    no-repeat right center/contain;
}
.questionAttach {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
}
.questionAttach i {
  font-size: 50px;
  color: var(--pbrown);
}
.questionAttach:hover i {
  opacity: 50%;
}
.questionSave {
  border: none;
  width: 150px;
  height: 50px;
  background: url("../../../../static/businessSlidesBtns/btn-save-normal.png")
    no-repeat right center/contain;
  cursor: pointer;
}
.questionSave:hover {
  background: url("../../../../static/businessSlidesBtns/btn-save-hover.png")
    no-repeat right center/contain;
}
.questionSave:active {
  background: url("../../../../static/businessSlidesBtns/btn-save-pressed.png")
    no-repeat right center/contain;
}
.questionSave:disabled {
  background: url("../../../../static/businessSlidesBtns/btn-save-hover.png")
    no-repeat right center/contain;
}
.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
@media screen and (max-width: 450px) {
  .formButtons {
    margin: auto;
  }
  .formSubmission div {
    flex-direction: column;
    align-items: center;
  }
}
